import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Button from 'components/button/button'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'
import SectionHeader from 'components/new/section-header'
import P from 'components/new/typography/p'
import Spacer from 'components/new/spacer'

const ThankYouPage = () => {
  return (
    <Layout kind='white'>
      <Helmet>
        <title>Thank You | Hutson Inc</title>
        <meta name='robots' content='noindex, nofollow' />
      </Helmet>

      <Content>
        <SectionHeader type='h1'>Thank you!</SectionHeader>
        <SectionHeaderP>
          Your form has been submitted. Our team will be in touch with you soon!
        </SectionHeaderP>
        <Spacer size='m' />
        <StyledButton onPress={() => navigate(-1)}>Go back</StyledButton>
      </Content>
    </Layout>
  )
}

const SectionHeaderP = styled(P)`
  margin-top: 0;
  text-align: center;
`

const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
  max-width: 200px;
  text-decoration: none;
  width: 100%;
`

export default ThankYouPage
